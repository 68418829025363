.chat-app {
  position: fixed;
  bottom: 24px;
  right: 0px;
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  justify-content: flex-end;
  width: 100%;
  max-width: 385px;
  z-index: 1030;
  padding: 0 1rem;
}
.chat-app ::-webkit-scrollbar {
  width: 2px;
}
.chat-app ::-webkit-scrollbar-track {
  background: var(--card-bg);
}
.chat-app ::-webkit-scrollbar-thumb {
  background: var(--scrollbar-thumb-color);
  border-radius: 10px;
  max-height: 30px;
}
.chat-app ::-webkit-scrollbar-thumb:hover {
  background: var(--gray-500);
}

.chat-element {
  height: 582px;
  width: 100%;
  position: relative;
  box-shadow: 0px 2px 6px rgba(17, 43, 66, 0.08), 0px 1px 4px rgba(17, 43, 66, 0.1);
  background: var(--card-bg);
  border-radius: 6px;
  margin-bottom: 1rem;
  border: 1px solid var(--dark-border-color);
}

.chat-container {
  padding: 0;
  margin: 0;
}

.chat-bubble {
  cursor: pointer;
  background: var(--primary-color);
  color: var(--white);
  border-radius: 100px;
  font-weight: bold;
  display: flex;
  align-items: center;
  width: initial;
}
.chat-bubble svg {
  fill: var(--white);
}
.chat-bubble:hover {
  box-shadow: var(--shadow-lg);
}
.chat-bubble .chat-message-icon {
  margin-right: var(--margin-sm);
  display: flex;
  align-items: center;
  justify-content: center;
}

.chat-cross-button {
  display: none;
  color: var(--gray-700);
  cursor: pointer;
  position: absolute;
  top: 12px;
  right: 8px;
}

.chat-bubble-closed {
  width: 60px;
  height: 60px;
  position: relative;
  text-align: center;
}
.chat-bubble-closed .cross-icon {
  width: 100%;
}

.chat-list .chat-list-header {
  padding: 12px 12px 0 12px;
  justify-content: space-between;
  display: flex;
}
.chat-list .chat-list-header .chat-list-icons {
  display: flex;
}
.chat-list .chat-list-header .chat-list-icons .user-settings {
  cursor: pointer;
  margin-right: 0px;
  margin-top: 1px;
}
.chat-list .chat-list-header .chat-list-icons .add-room {
  cursor: pointer;
  margin-right: 11px;
  margin-top: 1px;
}
.chat-list .chat-search {
  border-radius: 6px;
  font-size: 0.875rem;
  margin: 0 12px;
}
.chat-list .chat-search .search-icon {
  display: flex;
  align-items: center;
  position: absolute;
  padding-right: 10px;
  height: 100%;
  margin-left: 12px;
  z-index: 3;
}
.chat-list .chat-search .chat-search-box {
  width: 100%;
  border-radius: 6px;
  padding-left: 36px;
}
.chat-list .chat-search .chat-search-box:focus {
  box-shadow: none;
}
.chat-list .chat-rooms-container {
  height: 490.5px;
  overflow-y: scroll;
  padding: 12px;
}
.chat-list .chat-rooms-container .chat-room {
  display: flex;
  font-size: 0.875rem;
  cursor: pointer;
  position: relative;
  padding: 10px 0;
  border-radius: 8px;
}
.chat-list .chat-rooms-container .chat-room:hover {
  background: var(--bg-light-gray);
  /* Neat trick to add hover spacing without affecting layout */
  border-left: 5px solid var(--bg-light-gray);
  border-right: 5px solid var(--bg-light-gray);
  margin: 0 -5px;
}
.chat-list .chat-rooms-container .chat-room .chat-profile-info {
  margin-left: var(--margin-md);
  margin-right: auto;
}
.chat-list .chat-rooms-container .chat-room .chat-profile-info .chat-name {
  font-weight: bold;
  display: flex;
  align-items: center;
}
.chat-list .chat-rooms-container .chat-room .chat-date {
  color: var(--text-muted);
}
.chat-list .chat-rooms-container .chat-room .chat-latest {
  width: 4px;
  height: 4px;
  margin-left: 5px;
  background: var(--blue-500);
  border-radius: 50%;
}

.chat-header {
  display: flex;
  align-items: center;
  padding: 12px;
  box-shadow: 0px 1px 4px rgba(0, 0, 0, 0.06), 0px 2px 0.5px -1px rgba(0, 0, 0, 0.03);
  border-bottom: 1px solid var(--dark-border-color);
}
.chat-header .chat-back-button {
  height: 28px;
  width: 28px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
  background: var(--bg-color);
  margin-right: 10px;
  cursor: pointer;
}
.chat-header .chat-profile-info {
  margin-left: var(--margin-md);
  font-size: 0.875rem;
  margin-right: auto;
}
.chat-header .chat-profile-info .chat-profile-name {
  color: var(--text-color);
  font-weight: 600;
}
.chat-header .chat-profile-info .chat-profile-status {
  font-size: 0.75rem;
  visibility: hidden;
}
.chat-header .online-circle {
  display: inline-block;
  height: 0.5rem;
  width: 0.5rem;
  border-radius: 50%;
  margin-left: 3px;
  background: var(--green);
}

.chat-space {
  height: 100%;
}
.chat-space .chat-space-container {
  padding: 12px;
  overflow-wrap: break-word;
  height: 475.5px;
  overflow-y: scroll;
}
.chat-space .chat-space-container .chat-image {
  border-radius: 13px 13px 0px 13px;
}
.chat-space .chat-space-container .message-name {
  font-size: 0.75rem;
  font-weight: bold;
}
.chat-space .chat-space-container .sender-message {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  margin-bottom: 5px;
}
.chat-space .chat-space-container .sender-message .message-bubble {
  background: var(--primary-color);
  color: var(--white);
  padding: 8px;
  font-size: 0.875rem;
  border-radius: 13px 13px 13px 0px;
  max-width: 78%;
}
.chat-space .chat-space-container .recipient-message {
  margin-bottom: 5px;
  display: flex;
  flex-direction: column;
  align-items: flex-end;
}
.chat-space .chat-space-container .recipient-message .message-bubble {
  background: var(--control-bg);
  color: var(--text-color);
  padding: 8px;
  font-size: 0.875rem;
  border-radius: 13px 13px 0px 13px;
  max-width: 78%;
}
.chat-space .chat-space-container .message-time {
  font-size: 0.75rem;
  color: var(--text-muted);
}
.chat-space .chat-space-container .date-line {
  width: 100%;
  text-align: center;
  border-bottom: 1px solid var(--gray-400);
  line-height: 0.1em;
  margin: 10px 0 20px;
}
.chat-space .chat-space-container .date-line span {
  background: var(--card-bg);
  padding: 0 8px;
  font-size: 0.875rem;
}
.chat-space .chat-space-actions {
  padding: 3px 12px 12px 12px;
  display: flex;
  align-items: center;
}
.chat-space .chat-space-actions .open-attach-items {
  cursor: pointer;
}
.chat-space .chat-space-actions .type-message {
  margin: 0 10px;
  border-radius: 100px;
  font-size: 0.875rem;
}
.chat-space .chat-space-actions .message-send-button {
  cursor: pointer;
  height: 2rem;
  width: 2rem;
  border-radius: 50%;
  background: var(--blue-500);
  display: flex;
  align-items: center;
  justify-content: center;
}
.chat-space .chat-space-actions .message-send-button svg {
  fill: var(--white);
}

.chat-form .chat-form-container {
  padding: 12px;
}
.chat-form .chat-form-container .chat-query-heading {
  font-size: 0.875rem;
  color: var(--gray-800);
}
.chat-form .chat-form-container button {
  font-size: 1rem;
}
.chat-form .chat-form-container .form-label {
  font-size: 0.75rem;
  font-weight: bold;
}
.chat-form .chat-form-container #chat-message-area {
  resize: none;
}

.chat-welcome .chat-welcome-header {
  padding: var(--padding-lg);
}
.chat-welcome .chat-welcome-header .hero-icon {
  height: 3rem;
  width: 3rem;
  border-radius: 50%;
  background: var(--blue-500);
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: var(--margin-md);
}
.chat-welcome .chat-welcome-header .hero-icon svg {
  fill: var(--white);
}
.chat-welcome .chat-welcome-header h3 {
  font-size: 2.25rem;
  font-weight: normal;
  margin-bottom: var(--margin-md);
}
.chat-welcome .chat-welcome-header p {
  font-size: 1.125rem;
}
.chat-welcome .chat-welcome-footer {
  position: absolute;
  padding: 190px var(--padding-md) 0px var(--padding-md);
  bottom: 0;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  background: var(--cyan-100);
}
.chat-welcome .chat-welcome-footer .status-content {
  width: 100%;
  margin-bottom: 0;
  font-size: 1.125rem;
  font-weight: bold;
}
.chat-welcome .chat-welcome-footer .hero-content {
  width: 100%;
  font-size: 0.75rem;
}
.chat-welcome .chat-welcome-footer button {
  margin-bottom: var(--margin-md);
  font-size: 1rem;
}
.chat-welcome .chat-welcome-footer .welcome-footer {
  position: initial;
  margin-bottom: var(--margin-sm);
}

.chat-footer {
  width: 100%;
  display: flex;
  position: absolute;
  bottom: 8px;
  justify-content: center;
  font-size: 0.875rem;
  color: var(--gray-600);
}
.chat-footer:hover {
  text-decoration: none;
}

@media (max-width: 576px) {
  .chat-app {
    max-width: 100%;
    bottom: 1rem;
  }

  .chat-bubble-closed {
    display: none;
  }

  .chat-cross-button {
    display: block;
  }

  .chat-list .chat-list-header .chat-list-icons {
    margin-right: var(--margin-xl);
  }
}
.chat-navbar-icon {
  cursor: pointer;
}
.chat-navbar-icon .badge {
  background: var(--red-avatar-color);
  color: var(--bg-color);
  position: relative;
  top: -10px;
  left: -10px;
  border-radius: 50%;
  font-size: 9px;
  border: 1px solid var(--red-avatar-color);
}